import React, { useState, useEffect } from 'react';
import './Welcome.css';

// Use environment variables for endpoint and token
const ENDPOINT = process.env.REACT_APP_ENDPOINT;
// const ENDPOINT = localStorage.getItem('REACT_APP_ENDPOINT');

// Assuming you have your token stored in an environment variable
const STATIC_TOKEN = process.env.REACT_APP_STATIC_TOKEN;

function Welcome() {
  const [qrCode, setQrCode] = useState('');

  useEffect(() => {
    fetch(`${ENDPOINT}/api/current-qr`, {
      method: 'GET', // This is the default, but added for clarity
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${STATIC_TOKEN}`
      }
    })
    .then(response => response.json())
    .then(data => {
      setQrCode(data.qrCode); // Assuming the data returned has a qrCode field
    })
    .catch(error => console.error('Error fetching QR code:', error));
  }, []);

  return (
    <div className="welcome-wrapper">
      <div className="welcome"> {/* Changed class to className for JSX */}
          <div className="text-box">
            <h1>Skann QR-koden for å bli med!</h1>
          </div>
          {qrCode ? <img src={qrCode} alt="QR Code" style={{ height: '400px', width: '400px' }} /> : <p>Loading QR code...</p>}
      </div>
    </div>
  );
}

export default Welcome;