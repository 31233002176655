import React, { useEffect, useState, useMemo } from 'react';
import io from 'socket.io-client';
import './VoteDisplay.css'; // Ensure you have this CSS file

// Establish the socket connection outside of the component to avoid multiple connections
const ENDPOINT = process.env.REACT_APP_ENDPOINT;
// const ENDPOINT = localStorage.getItem('REACT_APP_ENDPOINT');

const socket = io(`${ENDPOINT}`);

function VoteDisplay({ onFetchNewData }) {
  const [votes, setVotes] = useState({ optionA: 0, optionB: 0 });
  const [storyData, setStoryData] = useState(null); // New state for storing fetched data
  const [maxVotes, setMaxVotes] = useState(25);
  
  useEffect(() => {
    // Fetch the story data
    const fetchData = async () => {
      const response = await fetch(`${ENDPOINT}/api/story/current`);
      const responseData = await response.json();
      setStoryData(responseData.data);
    };

    fetchData().catch(console.error);

    let accept_votes = false;
    socket.emit('identify', 'projector');

    socket.on('react-poll-start', () => {
      setTimeout(() => {
        accept_votes = true;
      }, 200);
    });

    socket.on('vote update', (newVotes) => {
      if (accept_votes) {
        setVotes(newVotes);

        setMaxVotes(prevMaxVotes => {
          if ((newVotes.optionA / prevMaxVotes >= 0.9) || (newVotes.optionB / prevMaxVotes >= 0.9)) {
            // return prevMaxVotes + 75;
            return prevMaxVotes + (prevMaxVotes*0.60);
          }
          return prevMaxVotes;
        });
      }
    });

    // socket.on('winner-picked', () => {
    socket.on('winner-picked', (data) => {
      // Placeholder for winning animation
      setTimeout(() => {
        document.getElementById(data.winner).classList.add('winner-anim');
        document.getElementById(data.loser).classList.add('loser-anim');
      }, 2500);

      console.log('winner-picked ws message receieved');

      // Prevent incoming votes
      setTimeout(() => {
        accept_votes = false;
      }, 1010);

      setTimeout(() => {
        console.log('Winner animation placeholder');
      }, 2000);

      // After animation (3000ms delay), call onFetchNewData from the Game component
      setTimeout(() => {
        // onFetchNewData();
        setTimeout(() => {
          document.getElementById(data.winner).classList.remove('winner-aninm');
          document.getElementById(data.loser).classList.remove('loser-aninm');
          // winner = null;
          // loser = null;
        }, 500);   
      }, 8500);
    });

    return () => {
      // socket.off('vote update');
      // socket.off('winner-picked');
      // socket.off('react-poll-start');
    };
  }, [onFetchNewData]);

  // Use useMemo to dynamically calculate widths based on the latest state
  const { widthA, widthB } = useMemo(() => {
    const widthA = Math.min((votes.optionA / maxVotes) * 100, 100);
    const widthB = Math.min((votes.optionB / maxVotes) * 100, 100);
    return { widthA, widthB };
  }, [votes, maxVotes]); // Depend on votes and maxVotes to recalculate

  // Determine the text for each option based on the storyData
  const optionTexts = storyData && storyData.type === 'choice' ? {
    optionA: storyData.options[0].text,
    optionB: storyData.options[1].text
  } : { optionA: '', optionB: '' };

  return (
    <div className="vote-container">
      <div id="optionA" className="vote-option">
        <div className="vote-bar-empty">
          <div className="vote-bar" style={{ width: `${widthA}%` }}></div>
        </div>
        {/* <div className="vote-text">Option A - {votes.optionA} votes</div> */}
        {/* <div className="vote-text">{optionTexts.optionA} - {votes.optionA} votes</div> */}
        <div className="vote-text">{optionTexts.optionA}</div>
      </div>
      <div id="optionB" className="vote-option">
        <div className="vote-bar-empty">
          <div className="vote-bar" style={{ width: `${widthB}%`, backgroundColor: 'grey' }}></div>
        </div>
        {/* <div className="vote-text">Option B - {votes.optionB} votes</div> */}
        {/* <div className="vote-text">{optionTexts.optionB} - {votes.optionB} votes</div> */}
        <div className="vote-text">{optionTexts.optionB}</div>
      </div>
    </div>
  );
}

export default VoteDisplay;