import React, { useState, useEffect, useRef } from 'react';
import socketIOClient from 'socket.io-client';
import Game from './components/Game/Game';
import Welcome from './components/Welcome/Welcome';
import Title from './components/Title/Title';
import Goodbye from './components/Goodbye/Goodbye';
import './App.css';

import gameAudio from './audio/metropolis.aac';

const ENDPOINT = process.env.REACT_APP_ENDPOINT;
// const ENDPOINT = localStorage.getItem('REACT_APP_ENDPOINT');

function App() {
  const [currentComponent, setCurrentComponent] = useState('Game');
  const [isFullScreen, setIsFullScreen] = useState(false);
  const gameMusic = useRef(new Audio(gameAudio));

  // let gameMusic = new Audio('https://istory-media.s3.eu-north-1.amazonaws.com/music/dead_city_short.aac');

  const handleFullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen().catch((e) => console.error(e));
      setIsFullScreen(true);
    }
  };

  const exitFullScreen = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen().catch((e) => console.error(e));
      setIsFullScreen(false);
    }
  };

  useEffect(() => {
    const handleEscapeKey = (event) => {
      if (event.key === 'Escape') {
        exitFullScreen();
      }
    };

    document.addEventListener('keydown', handleEscapeKey);

    // Fullscreen change listener to update the state accordingly
    const handleFullScreenChange = () => {
      const isCurrentlyFullScreen = !!document.fullscreenElement;
      setIsFullScreen(isCurrentlyFullScreen);
    };

    document.addEventListener('fullscreenchange', handleFullScreenChange);

    return () => {
      document.removeEventListener('keydown', handleEscapeKey);
      document.removeEventListener('fullscreenchange', handleFullScreenChange);
    };
  }, []);

  useEffect(() => {
    const socket = socketIOClient(ENDPOINT);
    
    
    socket.on('switch component', (data) => {
        setCurrentComponent(data.component);
        // Check and act based on the component after receiving the socket event
        if (data.component === 'Title') {
          setTimeout(() => {
            gameMusic.current.play();
          }, 200);
        }

        if (data.component === 'Goodbye') {
          setTimeout(() => {
            gameMusic.current.pause();
            gameMusic.current.currentTime = 0;
          }, 200);
        }
      });

    fetch(`${ENDPOINT}/api/currentComponent`)
    .then(response => response.json())
    .then(data => {
      setCurrentComponent(data.component);
      // Check and act based on the component after fetching it
      if (data.component === 'Title' || data.component === 'Game') {
        setTimeout(() => {
          gameMusic.current.play();
        }, 2000);
      }
    })
    .catch(console.error);

    return () => socket.disconnect();
  }, []);

  // useEffect hook to change body background color
  useEffect(() => {
    const componentColors = {
      Welcome: '#fff',
      Title: '#333',
      Game: '#333',
      Goodbye: '#fff',
    };

    // Set body background color based on the current component
    document.body.style.backgroundColor = componentColors[currentComponent] || '#333'; // Fallback color is #333

  }, [currentComponent]); // This useEffect depends on currentComponent

  // Document title (tab title)
  useEffect(() => {
    document.title = "iStory"
  }, [])

  // Switch components
  const renderComponent = () => {
    switch(currentComponent) {
      case 'Welcome': return <Welcome />;
      case 'Title': return <Title />;
      case 'Game': return <Game />;
      case 'Goodbye': return <Goodbye />;
      default: return <Game />;
    }
  };

  const handlePlayMusic = () => {
    gameMusic.current.play();
  };


  return (
    <div>
      {!isFullScreen && (
        <>
          <button onClick={handleFullScreen} className="fullscreen-btn" style={{ position: 'fixed', bottom: 35, right: 35 }} >
              <svg viewBox="0 0 448 512" height="1em" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M32 32C14.3 32 0 46.3 0 64v96c0 17.7 14.3 32 32 32s32-14.3 32-32V96h64c17.7 0 32-14.3 32-32s-14.3-32-32-32H32zM64 352c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7 14.3 32 32 32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H64V352zM320 32c-17.7 0-32 14.3-32 32s14.3 32 32 32h64v64c0 17.7 14.3 32 32 32s32-14.3 32-32V64c0-17.7-14.3-32-32-32H320zM448 352c0-17.7-14.3-32-32-32s-32 14.3-32 32v64H320c-17.7 0-32 14.3-32 32s14.3 32 32 32h96c17.7 0 32-14.3 32-32V352z"
              ></path>
              </svg>
              <span className="tooltip">Fullscreen</span>
          </button>

          <button onClick={handlePlayMusic} style={{ position: 'fixed', bottom: 39, right: 85, background: 'none', border: 'none' }}>
            <svg viewBox="0 0 384 512" stroke="none" height="1em" xmlns="http://www.w3.org/2000/svg" class="playbutton"><path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z"></path></svg>
          </button>
        </>
      )}
        <div className="App">
          {renderComponent()}
        </div>
    </div>
  );
}

export default App;