import React from 'react';
import './Goodbye.css';

function Goodbye() {
  return (
    <div class="goodbye-container">
      <div class="goodbye-text">
        <h1 id="takk">Takk for at dere deltok.</h1>
        <h1>Søk<br />Informasjonsteknologi og medieproduksjon.</h1>
      </div>
    </div>
  );
}

export default Goodbye;