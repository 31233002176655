// import React, { useEffect, useState } from 'react';
import React, { useState } from 'react';
import titleImage from './titleImage.webp';
import './Title.css';

function Title() {
  const [showTitleImage, setShowTitleImage] = useState(false);

  const titleImageLoaded = () => {
    setTimeout(() => {
      setShowTitleImage(true);
    }, 50); //
  };

  
    return (
      <div className="title-container">
        <div className="title-image">
            <img src={titleImage} alt="En postapokalyptisk historie" width="800" height="800" 
              onLoad={() => {
                titleImageLoaded();
                // Here, you might want to trigger the animation again or perform additional actions once the image is loaded
              }}
              style={{opacity: showTitleImage ? 1 : 0, animation: showTitleImage ? 'fadeIn 2s ease-out forwards' : 'none'}}
            
            />
        </div>
      </div>
    );
  }
  
  export default Title;